<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "kǒu",
          hanzi: "口",
          english: "mouth",
          englishClass:"font-english-medium",
          image: require(`@/assets/img/12-Vocabulary/lesson-13-kou-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "rén",
          hanzi: "人",
          english: "person; people",
          englishClass:"font-english-medium",
          image: require(`@/assets/img/12-Vocabulary/lesson-13-ren-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "hé",
          hanzi: "和",
          english: "and; with",
          englishClass:"font-english-medium",
          image: require(`@/assets/img/12-Vocabulary/lesson-13-he-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "jiejie",
          hanzi: "姐姐",
          english: "big sister",
          englishClass:"font-english-medium",
          image: require(`@/assets/img/12-Vocabulary/lesson-13-jiejie-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "dìdi",
          hanzi: "弟弟",
          english: "little brother",
          englishClass:"font-english-medium",
          image: require(`@/assets/img/12-Vocabulary/lesson-13-didi-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "Wǒ jiā yǒu ... kǒu rén, ... hé  ...",
          hanzi: "我家有……口人，……和……",
          english: "There are ... people in my family,...and...",
          englishClass:"font-english-small",
          pinyinClass:"font-english-small",
          hanziClass:"font-english-small",
          image: require(`@/assets/img/12-Vocabulary/lesson-13-wo-jia-you-kou-ren.svg`),
          pinyinWrap: true,
          longtext: true,
          blue: true
        },
       
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
